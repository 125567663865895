import React from 'react'
import cn from 'classnames'
import styles from './BookGuardian.module.sass'
import Link from 'next/link'
import Image from '../../../Image'
import Icon from '../../../Icon'
import ScrollParallax from '../../../ScrollParallax'

const items = [
  {
    title: '1-hour Advising',
    color: '#F5A70B',
    images: '/img/icon-user-filled.svg',
    alt: 'user',
    content: 'Receive instant answers to your unique technical questions.',
  },
  {
    title: '1-day Training',
    color: '#9506CE',
    images: '/img/icon-medal-1.svg',
    alt: 'medal',
    content: 'Fill knowledge gaps where needed to improve your product.',
  },
  {
    title: 'Build together',
    color: '#5656EA',
    images: '/img/icon-lightning.svg',
    alt: 'lightning',
    content: 'Grow your team with unique engineers you can’t find anywhere else.',
  },
]

export const BookGuardian = ({ scrollToRef }) => {
  return (
    <div className={cn('section-bg', styles.section)} ref={scrollToRef}>
      <div className={cn('container', styles.container)}>
        <div className={styles.gallery}>
          <ScrollParallax animateIn="fadeInUp" offset={300}>
            <Image
              src="img/section-tech@1.5x.png"
              srcSet="img/section-tech@2x.png 2x"
              alt="Technologies"
              width="624"
              height="748"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn('h2', styles.title)}>Book a Guardian</h2>
          <div className={styles.info}>
            <p>Your experience is tailored to your team and product.</p>
            <p>
              Each of your developers connects with technical experts depending on their needs, tech
              stack, seniority, and current challenges.
            </p>
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div className={styles.icon} style={{ backgroundColor: x.color }}>
                  <img src={x.images} alt={x.alt} />
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.btns}>
            <Link href="/get-early-access">
              <a className={cn('button', styles.button)}>
                <span>Book a Guardian</span>
                <Icon name="arrow-right" size="14" />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookGuardian
