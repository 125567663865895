import * as React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Layout from '../components/website/Layout'
import Headers from '../components/website/sections/Header'
import Footer from '../components/website/sections/Footer'
import HeroHome from '../components/website/sections/HeroHome'
import Clients from '../components/website/sections/Clients'
import StepsBusiness from '../components/website/sections/Steps/Business'
import BookGuardian from '../components/website/sections/Business/BookGuardian'
import MeetGuardians from '../components/website/sections/Business/MeetGuardians'
import Connect from '../components/website/sections/Business/Connect'

const Index = ({ className }) => {
  return (
    <div className={className}>
      <GlobalStyle />
      <Layout
        title="Jungle Program: Help developers deliver better software faster"
        canonical="https://www.jungleprogram.com/"
        description="We are a network of experienced developers. We devote our time to guiding developers in their technical challenges."
      >
        <Headers />
        <HeroHome />
        <Clients />
        <StepsBusiness />
        <BookGuardian />
        <MeetGuardians />
        <Connect />
        <Footer />
      </Layout>
    </div>
  )
}

const GlobalStyle = createGlobalStyle`
  body, h1, h2, h3, h4, h5, h6, p, ol, ul, div {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif
  }
`

const StyledIndex = styled(Index)``

export default StyledIndex
