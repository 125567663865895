import React, { useState } from 'react'
import cn from 'classnames'
import styles from './MeetGuardians.module.sass'
import Link from 'next/link'
import ScrollParallax from '../../../ScrollParallax'
import Icon from '../../../Icon'
import Item from './Item'
import Slider from 'react-slick'

const trainersList = [
  {
    title: 'Guardians',
    courses: [
      {
        name: 'Nastassia Mikheichyk',
        image: '/img/avatar-guardian-1.png',
        position: 'Lead Frontend Engineer at Zezam',
      },
      {
        name: 'François Bayart',
        image: '/img/avatar-guardian-2.png',
        position: 'CTO of Kensu',
      },
      {
        name: 'Isabella Brookes',
        image: '/img/avatar-guardian-3.png',
        position: 'Platform Engineer at WeTransfer',
      },
      {
        name: 'Majid Hajian',
        image: '/img/avatar-guardian-4.png',
        position: 'Head of DevRel at Invertase.io',
      },
      {
        name: 'Mao-Hsiang Lien',
        image: '/img/avatar-guardian-5.png',
        position: 'Senior Devops Engineer at Flaconi',
      },
      {
        name: '50+ high-performing software engineers',
        image: '/img/avatar-guardian-6.png',
        position: '',
      },
    ],
  },
]

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
)

const MeetGuardians = () => {
  const options = []
  trainersList.map((x) => options.push(x.title))

  const [direction, setDirection] = useState(options[0])

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 100000,
        settings: 'unslick',
      },
    ],
  }

  return (
    <div className={styles.section}>
      <div className={cn('container', styles.container)}>
        <div className={styles.head}>
          <h2 className={cn('h2', styles.title)}>Meet the Guardians</h2>
          <div className={styles.info}>
            Guardians are high-performing software engineers ready to commit to helping you. We
            analyze your technical challenges, what's at stake, and connect your tech team with the
            right Guardians.
          </div>
          <div className={styles.nav}>
            <div className={styles.btns}>
              <Link href="/get-early-access">
                <a className={cn('button', styles.button)}>
                  <span>Book a Guardian</span>
                </a>
              </Link>
            </div>
            <div className={styles.btns}>
              <Link href="/become-a-guardian">
                <a className={cn('button-stroke', styles.buttonWhite)}>
                  <span>Become a Guardian</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.wrap}>
          <Slider className={cn('team-slider', styles.slider)} {...settings}>
            {trainersList
              .find((x) => x.title === direction)
              .courses.map((x, index) => (
                <ScrollParallax className={styles.slide} key={index}>
                  <Item className={styles.item} item={x} />
                </ScrollParallax>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default MeetGuardians
