import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Item.module.sass'

const Item = ({ item, className }) => {
  return (
    <>
      <div className={cn(styles.item, className)}>
        <div className={styles.avatar}>
          <img src={item.image} alt="Avatar" />
        </div>
        <p className={styles.man}>{item.name}</p>
        <p className={styles.position}>{item.position}</p>
      </div>
    </>
  )
}

export default Item
