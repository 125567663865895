import React from 'react'
import cn from 'classnames'
import styles from './HeroHome.module.sass'
import Image from '../../Image'
import Link from 'next/link'
import ScrollParallax from '../../ScrollParallax'

export const HeroGuardians = () => {
  return (
    <div className={styles.hero}>
      <div className={'container'}>
        <div className={styles.wrap}>
          <div className={cn('status-grey', styles.stage)}>
            limited spots for teams
          </div>
          <h1 className={cn('h1', styles.title)}>Make your tech team thrive</h1>
          <div className={styles.text}>
            <p>
              Match your tech team with high-performing software engineers.
              <br />
              Apply new knowledge. Solve your technical challenges.
            </p>
            <p>Connect. Get live feedback. Scale your product.</p>
          </div>
          <div className={styles.btns}>
            <Link href={'/get-early-access'}>
              <a className={cn('button', styles.button)}>Get early access</a>
            </Link>
          </div>
        </div>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <ScrollParallax animateIn="fadeInUp" delay={0}>
              <Image
                className={'tablet-hide'}
                src="img/hero-home@1.5x.png"
                srcSet="img/hero-home@2x.png 2x"
                alt="Jungle Program Users"
              />
              <Image
                className={'tablet-show'}
                src="img/hero-home-mobile@1.5x.png"
                srcSet="img/hero-home-mobile@2x.png 2x"
                alt="Jungle Program Users"
              />
            </ScrollParallax>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroGuardians
