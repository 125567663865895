import React from 'react'
import cn from 'classnames'
import styles from './Clients.module.sass'

const list = [
  {
    title: "Blablacar",
    url: "img/client-blablacar.png",
  },
  {
    title: "N26",
    url: "img/client-n26.png",
  },
  {
    title: "Nespresso",
    url: "img/client-nespresso.png",
  },
  {
    title: "Orange",
    url: "img/client-orange.png",
  },
  {
    title: "Auto1",
    url: "img/client-auto1.png",
  },
  {
    title: "Zalando",
    url: "img/client-zalando.png",
  },
]

export const Clients = () => {
  return (
    <div className={styles.clients}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title}>
          The Jungle Guardians help developers from
        </div>
        <div className={styles.list}>
          {list.map((x, index) => (
            <div className={styles.logo} key={index}>
              <img src={x.url} alt={x.title} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Clients
