import React from 'react'
import cn from 'classnames'
import styles from './Steps.module.sass'
import ScrollParallax from '../../ScrollParallax'

const items = [
  {
    title: 'Advisory, training or build',
    color: '#3772FF',
    images: 'img/icon-download.svg',
    content: 'Choose the experience you need for your team and product.',
  },
  {
    title: 'Share a challenge',
    color: '#9506CE',
    images: 'img/icon-user.svg',
    content: 'Answer a few questions about your tech choices and challenges.',
  },
  {
    title: 'Match with a Guardian',
    color: '#E00054',
    images: 'img/icon-route.svg',
    content: 'We’ll connect you with Guardians who can help you.',
  },
  {
    title: 'Deep dive analysis',
    color: '#F5A70B',
    images: 'img/icon-chart-bar.svg',
    content: 'Deep dive into the topic and explore potential solutions.',
  },
]

export const StepsBusiness = ({ scrollToRef }) => {
  return (
    <div className={cn('section', styles.section)} ref={scrollToRef}>
      <div className={cn('container', styles.container)}>
        <div className={styles.head}>
          <h2 className={cn('h2', styles.title)}>How does it work?</h2>
          <div className={styles.info}>
            Share your current technical issue at hand.
            <br />
            Unlock access to the network of Guardians
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item + ' ' + 'safari-border-fix'} key={index}>
              <div className={styles.preview} style={{ backgroundColor: x.color }}>
                <img src={x.images} alt={`Step ${index}`} />
              </div>
              <div className={styles.number}>Step {index}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StepsBusiness
