import React from 'react'
import cn from 'classnames'
import styles from './Connect.module.sass'
import Link from 'next/link'
import Image from '../../../Image'

export const Connect = () => {
  return (
    <div className={cn('section-border-top', styles.section)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.top}>
          <div className={cn('stage-small', styles.stage)}>Want to talk to a tech expert?</div>
          <h2 className={cn('h2', styles.title)}>Connect your team with the best engineers</h2>
          <div className={styles.info}>
            Drop us a line or two about a technical challenge in your tech team,
            <br />
            we will get back to you as soon as we have a match
          </div>
        </div>
        <div className={styles.btns}>
          <Link href="/get-early-access">
            <a className={cn('button', styles.button)}>Let’s take action 👋</a>
          </Link>
        </div>
      </div>
      <Image
        src="img/connect@1.5x.png"
        srcSet="img/connect@2x.png 2x"
        alt="Connect with the Guardians"
        width="588"
        height="406"
        className={styles.connectImage}
      />
    </div>
  )
}

export default Connect
